import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { toast } from "~/components/toast";
import { getAuthQuery } from "~/domains/auth/queries/useGetAuthQuery";
import { getPlanQuery } from "~/domains/payment/queries/useGetPlansQuery";
import { getActiveTestsQuery } from "~/domains/test/queries/getActiveTests";

export default function useAuthHandler() {
  const router = useRouter();
  const token = useToken();
  const authStore = useAuthStore();
  const loading = ref(true);
  const queryClient = useQueryClient();
  const isApp = useIsApp()

  const { mutate } = useMutation({
    mutationFn: async () => {
      const config = useRuntimeConfig();
      const authStore = useAuthStore();
      const client = GetApiClient(
        config.public.apiURL as string,
        token.value ?? "",
      );

      await useDevice().init()

      // Check if the user is authenticated
      try {
        await queryClient.fetchQuery(getAuthQuery())
      } catch (error) {
        authStore.logout();
        router.push("/");
        return;
      }

      const data = await queryClient.fetchQuery(getAuthQuery())

      if (data.user.type !== "user") {
        authStore.logout();
        toast.error("Entre em uma conta de estudante");
        router.push("/");
        return;
      }

      if (isApp) {
        await usePurchases().configure(data.user.id);
      }

      const [subjectsRes] = await Promise.all([
        client.student.subjects.$get(),
        queryClient.prefetchQuery(getPlanQuery()),
        queryClient.prefetchQuery(getActiveTestsQuery()),
      ])

      const subjectData = await subjectsRes.json();

      authStore.login(
        data.user as any,
        data.session as any,
        data.student as any,
      );

      authStore.$patch({
        studentStreak: data.studentStreak ?? {
          streak: 0,
          weeks: []
        },
        totalQuestionsToday: data.studentQuestionsToday,
        subjectsStars: data.subjectsStars,
        subjects: subjectData,
      });

      if (!authStore.student) {
        router.push("/cadastrar?etapa=2");
      }

      loading.value = false;
    },
  });

  const checkAuth = async () => {
    if (!token.value) {
      loading.value = false;
      router.push("/");
      return;
    }

    if (authStore.user) {
      if (!authStore.student) {
        router.push("/cadastrar?etapa=2");
        loading.value = false;
        return;
      }
    }

    mutate();
  }

  return {
    loading,
    checkAuth
  };
}
