import { queryOptions, useQuery } from "@tanstack/vue-query";
import { Purchases } from "@revenuecat/purchases-capacitor";

export const listAppOfferingsQuery = () => {
  return queryOptions({
    queryKey: ["app-offerings"],
    queryFn: async () => {
      const isApp = useIsApp()
      if (!isApp) return []

      const offerings = await Purchases.getOfferings();
      if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {  
        return offerings.current.availablePackages.map((pkg) => {
          return {
            ...pkg,
            product: {
              ...pkg.product,
              planTitle: pkg.product.subscriptionPeriod === 'P1Y' ? 'Anual' : 'Trimestral',
              priceDescription: pkg.product.subscriptionPeriod === 'P1Y' ? 'Por ano' : 'Por trimestre'
            }
          }
        });
      }
 

      return [];
    },
    staleTime: 0,
    gcTime: 0,
  });
};

export const useListAppOfferings = () => {
  return useQuery(listAppOfferingsQuery());
};