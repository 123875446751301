import { Purchases, type PurchasesPackage } from "@revenuecat/purchases-capacitor";
import { useMutation } from "@tanstack/vue-query";
import { toast } from "~/components/toast";

export const usePurchasePackage = () => {
  const router = useRouter()
  return useMutation({
    mutationFn: async (aPackage: PurchasesPackage) => {
      const api = useApi()
      await Purchases.purchasePackage({
        aPackage: aPackage,
      });

      const res = await api.payment.activateAppPurchase.$post()

      if (!res.ok) {
        throw new Error('')
      }

      await router.push('/sucesso')

      return res
    },
    onError: (error) => {
      console.log("purchase error", error)
      toast.error('Não foi possível processar a compra.')
    }
  });
};